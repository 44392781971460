import { dateFormat } from './../../utils/index';
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
@Component
export class DateRangeDimensions extends Vue {
  public getWeekNumber(date: Date): number {
    // 复制日期对象，以避免改变原始对象
    const day = new Date(date.getTime()) as any;
    // 获取所在年份的第一天（1月1日）
    const yearStart = new Date(day.getFullYear(), 0, 1);
    // 获取所在年（1月1日）所在周当年缺少几天
    const diffWeekDay = moment(yearStart).isoWeekday() % 7;
    // 计算日期距离当年第一天有多少天
    const diff = moment(new Date(date)).dayOfYear() + diffWeekDay;
    // 计算周数
    const weekNumber = Math.ceil(diff / 7);
    return weekNumber;
  }
  public getDateDimension(date: Date, dimension: 'year' | 'quarter' | 'month' | 'week'): string {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    // const day = date.getDate();

    switch (dimension) {
      case 'year':
        return year.toString();
      case 'quarter':
        return `${year}-${Math.ceil(month / 3)}`;
      case 'month':
        return `${year}-${String(month)
          .toString()
          .padStart(2, '0')}`;
      case 'week':
        // 这里我们使用ISO周次来获取周数，注意这可能与本地周定义不 同
        return `${year}-${this.getWeekNumber(date)
          .toString()
          .padStart(2, '0')}`;
      default:
        throw new Error('Invalid dimension');
    }
  }

  public generateDateRangeDimensions(
    startDate: any,
    endDate: any,
    dimensions: 'year' | 'quarter' | 'month' | 'week'
  ): Array<any> {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const dimensionData = new Set();
    const array = [];

    // 设置开始日期为当前月的第一天
    start.setDate(start.getDate());
    // 遍历日期范围
    // eslint-disable-next-line no-unmodified-loop-condition
    while (start <= end) {
      const dimValue = this.getDateDimension(start, dimensions);
      dimensionData.add(dimValue);
      array.push({ start: dateFormat(start), dimValue });
      // 增加一天以继续循环
      start.setDate(start.getDate() + 1);
    }
    return Array.from(dimensionData);
  }
}
