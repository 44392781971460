import { unitsMeasureGroupsService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { UnitOfMeasurementGroupResource } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'add-units-measure-groups'
})
export default class AddUnitsMeasureGroups extends mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public position!: UnitOfMeasurementGroupResource | null;

  public unitsMeasureGroupsForm: UnitOfMeasurementGroupResource = {
    id: undefined,
    code: '',
    name: '',
    datumCode: '',
    datumName: '',
    datumPrecision: 0,
    describe: ''
  };

  public resourceFormRules = {
    code: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('unitsMeasureGroups.inputCode')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          unitsMeasureGroupsService
            .checkCode(value, this.unitsMeasureGroupsForm?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('common.codeRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    name: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('unitsMeasureGroups.inputName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          unitsMeasureGroupsService
            .checkName(value, this.position?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.regionNameRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    datumPrecision: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value?.toString()) {
            callback(new Error(translation('unitsMeasureGroups.inputDatumPrecision')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    datumCode: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('unitsMeasureGroups.inputDatumCode')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          // 编辑时不校验
          if (!this.position?.id) {
            unitsMeasureGroupsService
              .checkDatumCode(value, this.position?.id)
              .then((isRepeat: boolean) => {
                if (isRepeat) {
                  callback(translation('common.codeRepeat'));
                  return;
                }
                callback();
              })
              .catch(error => {
                callback(error);
              });
          } else {
            callback();
          }
        },
        trigger: 'blur'
      }
    ],
    datumName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('unitsMeasureGroups.inputDatumName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          // 编辑时不校验
          if (!this.position?.id) {
            unitsMeasureGroupsService
              .checkDatumName(value, this.position?.id)
              .then((isRepeat: boolean) => {
                console.log(isRepeat);
                if (isRepeat) {
                  callback(translation('requestError.regionNameRepeat'));
                  return;
                }
                callback();
              })
              .catch(error => {
                callback(error);
              });
          } else {
            callback();
          }
        },
        trigger: 'blur'
      }
    ]
  };

  public operationType: 'add' | 'edit' = 'add';

  public dialogOpen(): void {
    if (this.position) {
      this.operationType = 'edit';
      this.title = 'unitsMeasureGroups.title';
      this.$nextTick(() => {
        Object.assign(this.unitsMeasureGroupsForm, this.position);
      });
      this.$forceUpdate();
      return;
    }
    this.operationType = 'add';
    this.title = 'unitsMeasureGroups.title';
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.unitsMeasureGroupsForm as Form).resetFields();
  }

  public onSubmit(): void {
    (this.$refs.unitsMeasureGroupsForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        if (this.operationType === 'add') {
          await unitsMeasureGroupsService.post({ ...this.unitsMeasureGroupsForm } as any);
          Message.success(translation('operationRes.operationSuccess'));
          this.$emit('operate-success');
          this.closeDialog();
          return;
        }
        await unitsMeasureGroupsService.put({ ...this.unitsMeasureGroupsForm } as any);
        Message.success(translation('operationRes.operationSuccess'));
        this.$emit('operate-success', this.unitsMeasureGroupsForm);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }
}
