import { Component, Watch } from 'vue-property-decorator';
import { OsTable } from '@/components';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { CompleteManuscriptWorkContentInfo } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { messageError, dateFormat, debounce, downloadFileByBlob, translation } from '@/utils';
import { Message } from 'element-ui';
import { ImportTemplateEnum, OrderTypeEnum } from '@/resource/enum';
import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
import { preperssStatisticsService } from '@/api/';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { Paging, SortOptions } from '@/api/base';
import { ImportFile } from '@/views/dialogs';
import EditWorkContent from './edit-work-content/edit-work-content.vue';
import WorkContentDetails from './work-content-details/work-content-details.vue';
import { uniqueId, cloneDeep } from 'lodash-es';

@Component({
  name: 'CompleteManuscriptWorkContentInfo',
  components: { ImportFile, EditWorkContent, WorkContentDetails }
})
export default class CompleteManuscriptWorkContent extends mixins(PagingMixin, CustomColumnMixin) {
  public tableOption: OsTableOption<CompleteManuscriptWorkContentInfo> = {
    loading: false,
    data: [],
    fit: true,
    defaultSort: { order: 'ascending', prop: 'itemCode' },
    rowKey: (): string => {
      return 'key';
    }
  };

  public sortOptions: SortOptions<CompleteManuscriptWorkContentInfo> = this.tableOption.defaultSort!;

  /**
   * 默认的表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<CompleteManuscriptWorkContentInfo>> = [
    {
      type: 'selection',
      prop: 'key',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'itemCode',
      label: 'prepress.itemCode',
      minWidth: '230px',
      sortable: 'custom',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'modifyCount',
      label: 'prepress.round',
      minWidth: '100px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'itemType',
      label: 'prepressStatistics.orderType',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        const itemType = (row as CompleteManuscriptWorkContentInfo).itemType;
        return translation(`projectItemType.${OrderTypeEnum[itemType]}`);
      }
    },

    {
      prop: 'projectName',
      label: 'order.projectName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerName',
      label: 'project.customer',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformProductName',
      label: 'prepress.platformProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'prepressRemark',
      label: 'prepress.prepressRemark',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleHeight',
      label: 'prepress.visibleSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as CompleteManuscriptWorkContentInfo).visibleWidth} × ${
          (row as CompleteManuscriptWorkContentInfo).visibleHeight
        }`;
      }
    },
    {
      prop: 'finishHeight',
      label: 'prepress.finishSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as CompleteManuscriptWorkContentInfo).finishWidth} × ${
          (row as CompleteManuscriptWorkContentInfo).finishHeight
        }`;
      }
    },
    {
      prop: 'unitArea',
      label: 'prepressTask.screenArea',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressCraftsName',
      label: 'projectProduct.prepressCrafts',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressSubmitCount',
      label: 'projectProduct.prepressSubmitCount',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'factor',
      label: 'prepressCrafts.factor',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'workload',
      label: 'prepressStatistics.workload',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: object): number => {
        return (
          (row as CompleteManuscriptWorkContentInfo).factor *
          (row as CompleteManuscriptWorkContentInfo).prepressSubmitCount
        );
      }
    },
    {
      prop: 'makeUserName',
      label: 'prepress.makeUserName',
      showOverflowTooltip: true,
      minWidth: '180px'
    },

    {
      prop: 'makeStateTime',
      label: 'prepress.makeStateTime',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as CompleteManuscriptWorkContentInfo).makeStateTime, 'YYYY-MM-DD HH:mm');
      }
    },
    {
      prop: 'makeFinishTime',
      label: 'prepressStatistics.PressingTime',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as CompleteManuscriptWorkContentInfo).makeFinishTime, 'YYYY-MM-DD HH:mm');
      }
    },
    {
      prop: 'updateReason',
      label: 'prepressStatistics.updateReason',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'updateTime',
      label: 'database.updateTime',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as CompleteManuscriptWorkContentInfo).updateTime);
      }
    },
    {
      prop: 'updateUserName',
      label: 'database.updateUserName',
      showOverflowTooltip: true,
      minWidth: '180px'
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption<any>> = [
    {
      type: 'Input',
      field: 'makeUserName',
      label: 'prepressStatistics.processedBy',
      option: {
        placeholder: 'prepressStatistics.processedBy'
      }
    },
    {
      type: 'Select',
      field: 'itemType',
      label: 'prepressStatistics.orderType',
      option: {
        placeholder: 'prepressStatistics.orderType'
      },
      optionData: preperssStatisticsService.getOrderTypeList()
    },
    {
      type: 'DateRangePicker',
      field: 'makeTime',
      label: 'prepressStatistics.PressingTime',
      option: {
        rangeSeparator: '~',
        pickerOptions: {
          disabledDate(callbackDateStr: string): boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();
            return callbackDateTime >= currentDateTime;
          }
        },
        clearable: false
      }
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.export',
      operationType: 'export',
      icon: 'el-icon-download',
      plain: true,
      permissionCode: 'prepress:statistics:detail:export',
      handleClick: this.export
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'prepressStatistics.batchEditWorkload',
      operationType: 'batchEdit',
      disabled: true,
      permissionCode: 'prepress:statistics:edit',
      handleClick: (): void => {
        this.openEditWorkload();
      }
    }
  ];

  public detailsParams: any = {};
  public dialogVisible: boolean = false;
  public dialogDetailsVisible: boolean = false;

  public importTitle = 'database.import';
  /**
   * 导入模板的名称
   */
  public importTemplate: ImportTemplateEnum = ImportTemplateEnum.prepressCrafts;

  public editRows: Array<CompleteManuscriptWorkContentInfo> = [];

  public selectedRows: Array<CompleteManuscriptWorkContentInfo> = [];
  public paging: Paging = {
    currentPage: 1,
    showCount: 10
  };
  public queryForm: Partial<any> = {
    makeUserName: '',
    itemType: '',
    makeTime: ''
  };

  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'completeManuscriptWorkContent');
    this.loadData();
  }

  public activated(): void {
    this.loadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.clearSelection();
    this.loadData();
  }

  public handleSortChange(sortOptions: SortOptions<CompleteManuscriptWorkContentInfo>): void {
    this.sortOptions = sortOptions;
    this.reloadData();
  }
  // TODO
  public linkToDetails(row: CompleteManuscriptWorkContentInfo): void {
    // 打开详情 弹窗
    if (row.id) {
      this.detailsParams = { id: row.id, itemType: row.itemType, modifyCount: row.modifyCount };
      this.dialogDetailsVisible = true;
    } else {
      Message.info('该数据ID异常');
    }
  }

  public handleSelectionChange(selectedData: Array<CompleteManuscriptWorkContentInfo>): void {
    this.selectedRows = selectedData;
  }
  // 批量修改工作量
  public openEditWorkload(): void {
    if (this.selectedRows.length === 0) {
      Message.error(translation('common.selectminDataTip'));
      return;
    }
    this.editRows = cloneDeep(this.selectedRows);
    this.dialogVisible = true;
  }

  @debounce()
  public loadData(): void {
    this.tableOption.loading = true;
    preperssStatisticsService
      .getListPage(this.queryForm, this.paging, this.sortOptions)
      .then(res => {
        res.data.forEach(x => {
          x.key = uniqueId();
          x.workload = 0;
          x.showEdit = true;
          x.prepressCraftsIds = [];
          x.prepressCraftsList = [];
        });
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private async export(): Promise<void> {
    this.tableOption.loading = true;
    try {
      const blob = await preperssStatisticsService.detailExport(this.queryForm);
      downloadFileByBlob(`${translation('route.completeManuscriptWorkContent')}_${dateFormat(new Date())}.xlsx`, blob);
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<CompleteManuscriptWorkContentInfo>): void {
    this.operationOptions.forEach(x => {
      if (['batchEdit'].includes(x.operationType)) {
        x.disabled = value.length === 0;
      }
    });
  }

  private clearSelection(): void {
    (this.$refs.completeManuscriptWorkContentTable as OsTable).clearSelection();
    this.selectedRows = [];
  }

  @Watch('queryForm.makeTime')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.makeStartTime = undefined;
      this.queryForm.makeEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.makeStartTime = dateFormat(value[0]);
      this.queryForm.makeEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }
}
