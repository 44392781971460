import { unitsMeasureService, unitsMeasureGroupsService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { UnitOfMeasurementResource } from '@/resource/model';
import { NormalSelectOptions } from '@/resource/model/common';
import { RegionTreeResource } from '@/resource/model/public-data/region';
import { messageError, translation } from '@/utils';
import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'AddUnitMeasure'
})
export default class AddUnitMeasure extends mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public position!: UnitOfMeasurementResource | null;
  @Prop({
    required: true,
    default: () => {
      return undefined;
    }
  })
  public parentData!: RegionTreeResource;

  public unitMeasureForm: UnitOfMeasurementResource = {
    id: undefined,
    code: '',
    name: '',
    groupId: null,
    precision: 0,
    denominator: 1.0,
    numerator: 1.0,
    datumUnitId: null,
    datumUnitName: '',
    describe: ''
  };

  public resourceFormRules = {
    code: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('unitsMeasure.inputCode')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          unitsMeasureService
            .checkCode(value, this.unitMeasureForm?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('common.codeRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    name: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('unitsMeasure.inputName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          unitsMeasureService
            .checkName(value, this.position?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.regionNameRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    groupId: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('unitsMeasure.selectGroupId')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    denominator: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('unitsMeasure.inputDenominator')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    numerator: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('unitsMeasure.inputNumerator')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    precision: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value?.toString()) {
            callback(new Error(translation('unitsMeasure.inputPrecision')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  };

  public groupList: NormalSelectOptions = [];

  private operationType: 'add' | 'edit' = 'add';

  public dialogOpen(): void {
    this.getCurrencyList();
    if (this.position) {
      this.operationType = 'edit';
      this.title = 'unitsMeasure.title';
      this.$nextTick(() => {
        Object.assign(this.unitMeasureForm, this.position);
      });
      return;
    }
    this.operationType = 'add';
    this.title = 'unitsMeasure.title';
    if (this.parentData.id > 0) {
      this.unitMeasureForm.groupId = this.parentData.id;
      this.changeGroup(this.unitMeasureForm.groupId);
    }
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.unitMeasureForm as Form).resetFields();
  }

  public onSubmit(): void {
    (this.$refs.unitMeasureForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        if (this.operationType === 'add') {
          await unitsMeasureService.post({ ...this.unitMeasureForm } as any);
          Message.success(translation('operationRes.operationSuccess'));
          this.$emit('operate-success');
          this.closeDialog();
          return;
        }
        await unitsMeasureService.put({ ...this.unitMeasureForm } as any);
        Message.success(translation('operationRes.operationSuccess'));
        this.$emit('operate-success', this.unitMeasureForm);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }

  public async getCurrencyList(): Promise<void> {
    try {
      this.groupList = (await unitsMeasureGroupsService.getUnitsOfMeasureList()).map(x => {
        return {
          label: x.name,
          value: x.id
        };
      });
    } catch (error) {
      messageError(error);
    }
  }
  public async changeGroup(value: number): Promise<void> {
    try {
      const { name, id } = await unitsMeasureService.getDatumName(value);
      this.unitMeasureForm.datumUnitName = name;
      this.unitMeasureForm.datumUnitId = id;
    } catch (error) {
      messageError(error);
    }
  }
}
