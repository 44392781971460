import { render, staticRenderFns } from "./units-measure-groups-details.vue?vue&type=template&id=3c5cc1ef&scoped=true&"
import script from "./units-measure-groups-details.ts?vue&type=script&lang=ts&"
export * from "./units-measure-groups-details.ts?vue&type=script&lang=ts&"
import style0 from "./units-measure-groups-details.scoped.scss?vue&type=style&index=0&id=3c5cc1ef&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c5cc1ef",
  null
  
)

export default component.exports