import { DialogMixin } from '@/mixins/dialog';
import { RegionLevelEnum } from '@/resource/enum';
import { PersonnelResource, Region } from '@/resource/model';
import { NormalSelectOptions } from '@/resource/model/common';
import { RegionTreeResource } from '@/resource/model/public-data/region';
import { translation } from '@/utils';
import { Form } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
@Component({})
export default class RegionDetails extends mixins(DialogMixin) {
  @Prop({
    required: false,
    default: () => {
      return null;
    }
  })
  public region!: Region | null;
  @Prop({
    required: false,
    default: () => {
      return null;
    }
  })
  public parentData!: RegionTreeResource;
  @Prop({
    required: false,
    default: () => {
      return [];
    }
  })
  public treeList!: Array<RegionTreeResource>;

  public levelList: Array<any> = [];

  public regionForm: {
    name: string;
    code: string;
    level: number | null;
    describe: string;
    parentId: number | null;
    parentValue: Array<number>;
  } = {
    name: '',
    code: '',
    level: null,
    describe: '',
    parentId: null,
    parentValue: []
  };

  public personnelOptions: Array<PersonnelResource> = [];
  private operationType: 'add' | 'edit' = 'add';

  /**
   * 获取行政区划等级
   * @returns
   */
  public getLevelList(): NormalSelectOptions {
    const list: NormalSelectOptions = [];
    for (const key in RegionLevelEnum) {
      if (isNaN(Number(key))) {
        list.push({
          label: translation(`region.${key}`),
          value: RegionLevelEnum[key]
        });
      }
    }
    return list;
  }

  public dialogOpen(): void {
    this.title = 'region.administrativeDivisions';
    this.levelList = this.getLevelList();
    this.regionForm.parentValue = this.parentData.valueList;
    this.regionForm.parentId = this.parentData.id;
    this.regionForm.name = '';
    this.regionForm.code = '';
    this.regionForm.level = this.parentData.level + 1;
    this.regionForm.describe = '';
    if (!this.region) {
      this.operationType = 'add';
      return;
    }
    this.operationType = 'edit';
    this.$nextTick(() => {
      Object.assign(this.regionForm, this.region);
    });
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.regionForm as Form).resetFields();
    this.regionForm = {
      name: '',
      code: '',
      level: null,
      describe: '',
      parentId: null,
      parentValue: []
    };
  }
}
