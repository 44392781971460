import { unitsMeasureGroupsService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { ResourceStatusEnum, PublicApproveStatusEnum } from '@/resource/enum';
import { UnitOfMeasurementList } from '@/resource/model';
import { NormalSelectOptions } from '@/resource/model/common';
import { messageError, translation } from '@/utils';
import { Form } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'UnitMeasureDetails'
})
export default class UnitMeasureDetails extends mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public position!: UnitOfMeasurementList | null;

  public unitMeasureForm: UnitOfMeasurementList = {
    id: 0,
    code: '',
    name: '',
    groupId: null,
    precision: 0,
    denominator: 1.0,
    numerator: 1.0,
    datumUnitId: null,
    datumUnitName: '',
    describe: '',
    createTime: '',
    createUserName: '',
    updateTime: '',
    updateUserName: '',
    forbiddenTime: '',
    forbiddenUserName: '',
    approveTime: '',
    approveUserName: '',
    enableStatus: 0,
    approveStatus: 0,
    datumFlag: 0,
    systemFlag: 0
  };
  public groupList: NormalSelectOptions = [];
  public disabled: boolean = true;

  private operationType: 'add' | 'edit' = 'add';

  public dialogOpen(): void {
    this.getGroupList();
    if (this.position) {
      this.operationType = 'edit';
      this.title = 'unitsMeasure.title';
      this.$nextTick(() => {
        Object.assign(this.unitMeasureForm, this.position);
      });
      return;
    }
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.unitMeasureForm as Form).resetFields();
  }

  private get getApproveStatusI18Key(): string {
    return translation('publicApproveStatus.' + PublicApproveStatusEnum[this.unitMeasureForm.approveStatus]);
  }
  private get getStatusI18Key(): string {
    let res;
    switch (this.unitMeasureForm.enableStatus) {
      case ResourceStatusEnum.using:
        res = 'common.startUsing';
        break;
      case ResourceStatusEnum.disabled:
        res = 'common.disabled';
        break;
      default:
        res = 'common.unKnownStatus';
        break;
    }
    return translation(res);
  }

  public async getGroupList(): Promise<void> {
    try {
      this.groupList = (await unitsMeasureGroupsService.getUnitsOfMeasureList()).map(x => {
        return {
          label: x.name,
          value: x.id
        };
      });
    } catch (error) {
      messageError(error);
    }
  }
}
