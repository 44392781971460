import { Component, Vue } from 'vue-property-decorator';
import { departmentService } from '@/api';

import { OsTable, OsTableQuery, OsTableOperation } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { MessageBox, Message } from 'element-ui';
import { DepartmentResource } from '@/resource/model';
import { cloneDeep } from 'lodash-es';
import { getResourceStatusOptions, getStatusClass, getStatusI18Key, messageError, translation } from '@/utils';
import AddDepartment from './add-department/add-department.vue';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { ResourceStatusEnum } from '@/resource/enum';

@Component({
  components: { OsTable, AddDepartment, OsTableQuery, OsTableOperation }
})
export default class Department extends Vue {
  public tableOption: OsTableOption<DepartmentResource> = {
    loading: false,
    data: [],
    fit: true,
    bottomOffset: 0
  };

  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Select',
      field: 'status',
      label: 'common.status',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: getResourceStatusOptions
    }
  ];

  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'organize:department:save',
      handleClick: (): void => {
        this.openAddDialog();
      }
    }
  ];

  public currentSelectRow: DepartmentResource | null = null;

  public currentSelectRowId: number | null = null;

  public columnOption: Array<OsTableColumn<DepartmentResource>> = [
    {
      prop: 'depName',
      label: 'department.departmentName',
      minWidth: '190px',
      showOverflowTooltip: true
    },
    {
      prop: 'sort',
      label: 'department.sort',
      minWidth: '80px'
    },
    {
      prop: 'status',
      label: 'common.status',
      minWidth: '50px'
    },
    { prop: 'createTime', label: 'common.createTime', minWidth: '120px' }
  ];

  public operationOption: RowOperation<DepartmentResource> = {
    fixed: 'right',
    width: '200px',
    operations: [
      {
        operationType: 'add',
        type: 'text',
        label: 'button.add',
        icon: 'el-icon-circle-plus-outline',
        permissionCode: 'organize:department:save',
        handleClick: (rowData: DepartmentResource): void => {
          this.addChildDepartment(rowData);
        }
      },
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'organize:department:edit',
        handleClick: (rowData: DepartmentResource): void => {
          this.editDepartment(rowData);
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        permissionCode: 'organize:department:delete',
        handleClick: (rowData: DepartmentResource): void => {
          this.deleteDepartment(rowData);
        }
      }
    ]
  };

  public dialogVisible = false;

  private queryForm: Partial<{
    keywords: string;
    status: ResourceStatusEnum | null;
  }> = {
    keywords: '',
    status: null
  };

  public created(): void {
    this.loadData();
  }

  public queryClick(): void {
    this.loadData();
  }

  public dialogClosed(): void {
    this.currentSelectRow = null;
    this.currentSelectRowId = null;
  }

  public reloadTable(): void {
    this.loadData();
  }

  public getStatusI18Key(status: ResourceStatusEnum): string {
    return getStatusI18Key(status);
  }

  public getStatusClass(status: ResourceStatusEnum): string {
    return getStatusClass(status);
  }

  private addChildDepartment(data: DepartmentResource): void {
    this.openAddDialog(null, data.id);
  }

  private editDepartment(data: DepartmentResource): void {
    this.openAddDialog(data);
  }

  private deleteDepartment(data: DepartmentResource): void {
    MessageBox.confirm(translation('tip.confirmDelete'), translation('tip.tipInfo'), {
      confirmButtonText: translation('button.ok'),
      cancelButtonText: translation('button.cancel'),
      type: 'warning'
    })
      .then(async () => {
        try {
          await departmentService.delete(data.id);
          this.reloadTable();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private openAddDialog(data: DepartmentResource | null = null, id: number | null = null): void {
    if (data) {
      this.currentSelectRow = cloneDeep(data);
    }
    if (id) {
      this.currentSelectRowId = id;
    }
    this.dialogVisible = true;
  }

  private loadData(): void {
    this.tableOption.loading = true;
    departmentService
      .getList(this.queryForm as DepartmentResource)
      .then(res => {
        this.tableOption.data = res;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }
}
