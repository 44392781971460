var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"complete-manuscript-work-content"},[_c('el-card',{staticClass:"list-page-card",attrs:{"shadow":"never"}},[_c('os-table-query',{attrs:{"queryForm":_vm.queryForm,"queryItemsOption":_vm.queryItemsOption},on:{"query-click":_vm.reloadData}})],1),_c('el-card',{staticClass:"list-page-card",attrs:{"shadow":"never"}},[_c('os-table-operation',{attrs:{"operationOptions":_vm.operationOptions,"customColumns":_vm.customColumnOptions,"disableCustomColumns":['key']},on:{"column-change":function($event){return _vm.handleColumnChanged(
          $event,
          'completeManuscriptWorkContent',
          'completeManuscriptWorkContentTable'
        )},"column-reset":function($event){return _vm.handleColumnReset(
          _vm.defaultColumnOptions,
          'completeManuscriptWorkContent',
          'completeManuscriptWorkContentTable'
        )}}}),_c('os-table',{ref:"completeManuscriptWorkContentTable",attrs:{"tableOption":_vm.tableOption,"tableColumnOption":_vm.columnOptions},on:{"selection-change":_vm.handleSelectionChange,"sort-change":_vm.handleSortChange},scopedSlots:_vm._u([{key:"itemCode",fn:function(data){return [_c('el-link',{staticClass:"el-link-mini",attrs:{"type":"primary"},on:{"click":function($event){return _vm.linkToDetails(data.row)}}},[_vm._v(_vm._s(data.row.itemCode))])]}}])}),_c('os-pagination',{attrs:{"paging":_vm.paging,"total":_vm.totalData},on:{"pagination":_vm.loadData}})],1),_c('edit-work-content',{attrs:{"visible":_vm.dialogVisible,"editList":_vm.editRows},on:{"update:visible":function($event){_vm.dialogVisible=$event},"edit-success":_vm.reloadData}}),_c('el-dialog',{staticClass:"production-detials",attrs:{"title":_vm.$t('projectProduct.details'),"visible":_vm.dialogDetailsVisible,"width":'1000px',"top":"5vh","modal":true,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogDetailsVisible=$event}}},[(_vm.dialogDetailsVisible)?_c('work-content-details',{attrs:{"params":_vm.detailsParams || {}}}):_vm._e(),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){_vm.dialogDetailsVisible = false}}},[_vm._v(_vm._s(_vm.$t("button.close")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }