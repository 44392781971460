import { uniqueId } from 'lodash-es';
import { Component, Watch } from 'vue-property-decorator';
import { OsTable } from '@/components';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { PrepressStatistics } from '@/resource/model';

import { changeQueryTime, dateFormat, debounce, messageError, translation } from '@/utils';

import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
import { DateRangeDimensions } from '../dateDimension';
import { preperssStatisticsService } from '@/api/';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { Paging } from '@/api/base';
import { ImportFile } from '@/views/dialogs';
import echarts from 'echarts';
import { Message } from 'element-ui';

@Component({
  name: 'DepartmentAverageConfirmationRound',
  components: { ImportFile }
})
export default class DepartmentAverageConfirmationRound extends mixins(
  PagingMixin,
  CustomColumnMixin,
  DateRangeDimensions
) {
  public tableOption: OsTableOption<PrepressStatistics> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true
  };

  /**
   * 默认的表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<PrepressStatistics>> = [
    {
      type: 'selection',
      prop: 'key',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'time',
      label: 'prepressStatistics.statPeriod',
      minWidth: '230px',
      showOverflowTooltip: true
    },
    {
      prop: 'confirmTotal',
      label: 'prepressStatistics.confirmItemTotal',
      minWidth: '230px',
      showOverflowTooltip: true
    },
    {
      prop: 'reeditTotal',
      label: 'prepressStatistics.reeditTotal',
      minWidth: '230px',
      showOverflowTooltip: true
    },
    {
      prop: 'avgConfirmReedit',
      label: 'prepressStatistics.avgConfirmReedit',
      minWidth: '230px',
      showOverflowTooltip: true
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption<any>> = [
    {
      type: 'Select',
      field: 'dimension',
      label: 'prepressStatistics.dimension',
      option: {
        placeholder: 'prepressStatistics.dimension',
        clearable: false
      },
      optionData: preperssStatisticsService.getDimensionList()
    },
    {
      type: 'DateRangePicker',
      field: 'createTime',
      label: 'prepressStatistics.dateRange',
      option: {
        rangeSeparator: '~',
        pickerOptions: {
          disabledDate(callbackDateStr: string): boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();
            return callbackDateTime >= currentDateTime;
          }
        },
        clearable: false
      }
    }
  ];

  public selectedRows: Array<PrepressStatistics> = [];
  public paging: Paging = {
    currentPage: 1,
    showCount: 10
  };
  // TODO 类型未定义
  private queryForm: Partial<any> = {
    dimension: 'month',
    startTime: `${new Date().getFullYear()}-01-01 00:00:00`,
    endTime: `${dateFormat(new Date(), 'YYYY-MM-DD') + ' 23:59:59'}`,
    createTime: [
      `${new Date().getFullYear()}-01-01 00:00:00 `,
      dateFormat(dateFormat(new Date(), 'YYYY-MM-DD') + ' 23:59:59')
    ]
  };

  private myChart: any;

  private echartsList: Array<{ title: string; count: number }> = [];
  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'departmentAverageConfirmationRound');
    this.loadData();
  }

  public activated(): void {
    this.loadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.clearSelection();
    this.loadData();
  }

  public handleSelectionChange(selectedData: Array<PrepressStatistics>): void {
    this.selectedRows = selectedData;
  }

  // 初始化折线图
  public initEcharts(): void {
    // 自己的数据，我以time作为横坐标，count作为纵坐标
    const options: any = {
      type: 'category',
      color: ['#3398DB'],
      title: {
        text: '平均确稿轮数',
        left: 'center'
      },
      legend: {
        data: ['平均确稿轮数'],
        x: '10%', // 图例的水平位置设置为中间
        y: '20px' // 图例的水平位置设置为中间
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '5%',
        right: '2%',
        bottom: '8%'
      },
      xAxis: {
        type: 'category',
        nameLocation: 'center',
        data: this.echartsList.map(item => item.title)
      },
      yAxis: {
        type: 'value',
        name: '轮数',
        nameLocation: 'center',
        nameGap: 30,
        nameTextStyle: {
          color: '#2CAFFF'
        },
        axisLabel: {
          textStyle: {
            color: '#2CAFFF'
          }
        },
        axisLine: { show: false },
        axisPointer: {
          snap: true
        },
        max: function(value: any) {
          return value.max + 3;
        },
        data: this.echartsList.map(item => `${item.count}`)
      },
      series: [
        {
          name: '平均确稿轮数',
          type: 'line',
          smooth: true,
          data: this.echartsList.map(item => item.count),

          lineStyle: {
            color: '#2CAFFF', // 线条颜色
            width: 2 // 线条宽度
            // 其他线条样式属性
          },
          symbolSize: 8,
          symbol: 'circle',
          itemStyle: {
            // 高亮时点的颜色。
            color: '#2CAFFF'
          }
        }
      ]
    };
    this.myChart = echarts.init(this.$refs.myChart as any);
    this.myChart.setOption(options);
    // // 在初始化图表之后，重新加载（我这里是为了自适应宽度）
    setTimeout(() => {
      this.myChart.resize();
    }, 500);
  }

  @debounce()
  private loadData(): void {
    if (changeQueryTime(this.queryForm.startTime, this.queryForm.endTime)) {
      Message.error(translation('prepressStatistics.timeTip'));
      return;
    }
    const dimensionData = this.generateDateRangeDimensions(
      this.queryForm.startTime,
      this.queryForm.endTime,
      this.queryForm.dimension
    );
    this.tableOption.loading = true;
    this.echartsList = [];
    preperssStatisticsService
      .getDepStatistics(this.queryForm)
      .then(res => {
        this.tableOption.data = res || [];
        this.tableOption.data.forEach(x => {
          x.key = uniqueId();
        });
        dimensionData.forEach(dim => {
          let count = 0;
          const values = this.tableOption.data.filter(item => item.time === dim);
          if (values.length > 0) {
            count = values[0].avgConfirmReedit;
          }
          this.echartsList.push({
            title: dim,
            count: count
          });
        });
        this.initEcharts();
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }
  private clearSelection(): void {
    (this.$refs.departmentAverageConfirmationRoundTable as OsTable).clearSelection();
    this.selectedRows = [];
  }

  @Watch('queryForm.createTime')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.startTime = undefined;
      this.queryForm.endTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.startTime = dateFormat(value[0]);
      this.queryForm.endTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }
}
