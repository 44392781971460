import { regionService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { RegionLevelEnum } from '@/resource/enum';
import { PersonnelResource, Region } from '@/resource/model';
import { NormalSelectOptions } from '@/resource/model/common';
import { RegionTreeResource } from '@/resource/model/public-data/region';
import { messageError, translation } from '@/utils';
import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
@Component({})
export default class AddRegion extends mixins(DialogMixin) {
  @Prop({
    required: false,
    default: () => {
      return null;
    }
  })
  public region!: Region | null;
  @Prop({
    required: false,
    default: () => {
      return null;
    }
  })
  public parentData!: RegionTreeResource;
  @Prop({
    required: false,
    default: () => {
      return [];
    }
  })
  public treeList!: Array<RegionTreeResource>;

  public levelList: Array<any> = [];

  public regionForm: {
    name: string;
    code: string;
    level: number | null;
    describe: string;
    parentId: number | null;
    parentValue: Array<number>;
  } = {
    name: '',
    code: '',
    level: null,
    describe: '',
    parentId: null,
    parentValue: []
  };

  public resourceFormRules = {
    name: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('region.inputName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          regionService
            .checkRegionName(value, this.regionForm.parentId, this.region?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('common.nameRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    code: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('region.inputCode')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          const patrn = /^[0-9]*$/;
          console.log(patrn.test(value));
          if (!patrn.test(value)) {
            callback(new Error(translation('region.invalidFormat')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          regionService
            .checkRegionCode(value, this.region?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('common.codeRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    level: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('region.selectLevel')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  public personnelOptions: Array<PersonnelResource> = [];
  private operationType: 'add' | 'edit' = 'add';

  /**
   * 获取行政区划等级
   * @returns
   */
  public getLevelList(): NormalSelectOptions {
    const list: NormalSelectOptions = [];
    for (const key in RegionLevelEnum) {
      if (isNaN(Number(key))) {
        list.push({
          label: translation(`region.${key}`),
          value: RegionLevelEnum[key]
        });
      }
    }
    return list;
  }

  public dialogOpen(): void {
    this.title = 'region.administrativeDivisions';
    this.levelList = this.getLevelList();
    this.regionForm.parentValue = this.parentData.valueList;
    this.regionForm.parentId = this.parentData.id;
    this.regionForm.name = '';
    this.regionForm.code = '';
    this.regionForm.level = this.parentData.level + 1;
    this.regionForm.describe = '';
    if (!this.region) {
      this.operationType = 'add';
      return;
    }
    this.operationType = 'edit';
    this.$nextTick(() => {
      Object.assign(this.regionForm, this.region);
    });
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.regionForm as Form).resetFields();
    this.regionForm = {
      name: '',
      code: '',
      level: null,
      describe: '',
      parentId: null,
      parentValue: []
    };
  }

  public changeParentLevel(value: any): void {
    this.regionForm.parentId = value[value.length - 1];
    this.regionForm.level = value.length;
  }

  public onSubmit(): void {
    (this.$refs.regionForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        const form = { ...this.regionForm } as any;
        if (this.operationType === 'add') {
          await regionService.post(form);
          Message.success(translation('operationRes.addSuccess'));
          this.$emit('add-success');
          this.closeDialog();
          return;
        }
        await regionService.put(form);
        Message.success(translation('operationRes.editSuccess'));
        this.$emit('edit-success', form);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }
}
