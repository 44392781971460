import { Component, Vue, Watch } from 'vue-property-decorator';
import { OsTable } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { RegionResource } from '@/resource/model';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { Paging } from '@/api/base';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { regionService } from '@/api';
import {
  debounce,
  getResourceStatusOptions,
  getStatusClass,
  getStatusI18Key,
  messageError,
  translation
} from '@/utils';
import { Message } from 'element-ui';
import { RegionTreeResource } from '@/resource/model/public-data/region';
import { RegionLevelEnum, ResourceStatusEnum } from '@/resource/enum';
import AddRegion from './add-region/add-region.vue';
import RegionDetails from './region-details/region-details.vue';

@Component({
  components: {
    AddRegion,
    RegionDetails
  }
})
export default class Region extends Vue {
  public tableOption: OsTableOption<RegionResource> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<RegionResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'code',
      label: 'region.code',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'name',
      label: 'region.name',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'level',
      label: 'region.administrativeLevel',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return translation(`${regionService.getLevelI18Key((row as RegionResource).level)}`);
      }
    },
    {
      prop: 'status',
      label: 'region.status',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'describe',
      label: 'region.description',
      minWidth: '150px',
      showOverflowTooltip: true
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'name',
      label: 'region.name',
      option: {
        placeholder: 'common.input'
      }
    },
    {
      type: 'Input',
      field: 'code',
      label: 'region.code',
      option: {
        placeholder: 'common.input'
      }
    },
    {
      type: 'Select',
      field: 'status',
      label: 'common.status',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: getResourceStatusOptions
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      icon: 'el-icon-circle-plus-outline',
      disabled: false,
      operationType: 'add',
      permissionCode: 'system:region:save',
      handleClick: (): void => {
        this.regionAdd();
      }
    },
    {
      type: 'warning',
      plain: true,
      slot: 'start',
      label: 'region.refreshCache',
      disabled: false,
      operationType: 'refreshCache',
      permissionCode: 'customerAllocation:cancelAssignment',
      handleClick: (): void => {
        console.log('刷新缓存');
        this.refreshCache();
      }
    },
    {
      type: 'primary',
      plain: false,
      icon: 'el-icon-open',
      slot: 'end',
      label: 'common.startUsing',
      disabled: true,
      operationType: 'startUsing',
      permissionCode: 'customerAllocation:cancelAssignment',
      handleClick: (): void => {
        this.batchUpdateRegionStatus(1);
      }
    },
    {
      type: 'danger',
      plain: true,
      icon: 'el-icon-turn-off',
      slot: 'end',
      label: 'common.disabled',
      disabled: true,
      operationType: 'disabled',
      permissionCode: 'customerAllocation:cancelAssignment',
      handleClick: (): void => {
        this.batchUpdateRegionStatus(2);
      }
    }
  ];

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<RegionResource> = {
    fixed: 'right',
    width: '100px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'system:region:edit',
        handleClick: (item: RegionResource): void => {
          this.regionEdit(item);
        }
      }
    ]
  };
  public totalData = 0;
  public selectedRows: Array<RegionResource> = [];

  public treeList: Array<RegionTreeResource> = [
    {
      id: 0,
      level: 0,
      name: '中国',
      valueList: [0],
      child: []
    }
  ];

  public parentData: RegionTreeResource | null = null;

  public dialogVisible: boolean = false;
  public detailsDialogVisible: boolean = false;

  public editRow: RegionResource | null = null;

  public defaultProps: { children: string; label: string } = {
    children: 'child',
    label: 'name'
  };

  private queryForm: Partial<{
    name: string;
    parentId: number | null;
    code: string;
    status: number | null;
  }> = {
    parentId: 0,
    name: '',
    code: '',
    status: null
  };

  private paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public getStatusI18Key(status: ResourceStatusEnum): string {
    return getStatusI18Key(status);
  }

  public getStatusClass(status: ResourceStatusEnum): string {
    return getStatusClass(status);
  }

  public created(): void {
    this.loadData();
    this.getTreeList();
  }

  public queryClick(): void {
    this.reloadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    (this.$refs.regionTable as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }

  public handleSelectionChange(selectedData: Array<RegionResource>): void {
    this.selectedRows = selectedData;
  }

  public handleNodeClick(nodeData: any): void {
    console.log('nodeData', nodeData);
    this.parentData = nodeData;
    this.reloadData();
  }

  public handleTreeData(treeList: Array<RegionTreeResource>, parentValue: Array<number>): void {
    treeList.forEach(item => {
      item.valueList = [...parentValue, item.id];
      if (item.level === RegionLevelEnum.city) {
        item.child = undefined;
      }
      if (item.child && item.child.length > 0) {
        this.handleTreeData(item.child, item.valueList);
      }
    });
  }

  public regionAdd(): void {
    this.editRow = null;
    this.dialogVisible = true;
  }
  public regionEdit(item: RegionResource): void {
    this.editRow = item;
    this.dialogVisible = true;
  }
  public linkToItemDetails(item: RegionResource): void {
    this.editRow = item;
    this.detailsDialogVisible = true;
  }

  public dialogClosed(): void {
    this.editRow = null;
  }
  public editSuccess(): void {
    this.reloadData();
    this.getTreeList();
    this.editRow = null;
  }
  public refreshCache(): void {
    regionService
      .refreshCache()
      .then(() => {
        Message.success(translation('operationRes.operationSuccess'));
        this.loadData();
      })
      .catch(error => {
        messageError(error);
      });
  }
  public batchUpdateRegionStatus(status: number): void {
    const idList = this.selectedRows.map(x => x.id!);

    regionService
      .batchUpdateRegionStatus(idList, status)
      .then(() => {
        Message.success(translation('operationRes.operationSuccess'));
        this.reloadData();
        this.getTreeList();
      })
      .catch(error => {
        messageError(error);
      });
  }
  @debounce()
  private loadData(): void {
    this.tableOption.loading = true;
    this.queryForm.parentId = this.parentData!.id;
    regionService
      .getList(this.queryForm as RegionResource, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private getTreeList(): void {
    regionService
      .getTreeList(this.queryForm as RegionResource)
      .then(res => {
        this.treeList[0].child = res;
        this.handleTreeData(this.treeList, []);
        this.parentData = this.treeList[0];
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<any>): void {
    this.operationOptions.forEach(x => {
      if (!['add', 'refreshCache'].includes(x.operationType)) {
        x.disabled = value.length === 0;
      }
    });
  }
}
