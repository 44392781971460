import { render, staticRenderFns } from "./department-average-confirmation-round.vue?vue&type=template&id=5638d62a&scoped=true&"
import script from "./department-average-confirmation-round.ts?vue&type=script&lang=ts&"
export * from "./department-average-confirmation-round.ts?vue&type=script&lang=ts&"
import style0 from "./department-average-confirmation-round.scoped.scss?vue&type=style&index=0&id=5638d62a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5638d62a",
  null
  
)

export default component.exports