import { Component, Watch } from 'vue-property-decorator';
import { OsTable } from '@/components';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { PrepressStatistics } from '@/resource/model';

import { changeQueryTime, dateFormat, debounce, downloadFileByBlob, messageError, translation } from '@/utils';

import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
import { preperssStatisticsService } from '@/api/';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { Paging } from '@/api/base';
import { ImportFile } from '@/views/dialogs';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { Message } from 'element-ui';

@Component({
  name: 'DepartmentAverageConfirmationRate',
  components: { ImportFile }
})
export default class DepartmentAverageConfirmationRate extends mixins(PagingMixin, CustomColumnMixin) {
  public tableOption: OsTableOption<PrepressStatistics> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 默认的表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<PrepressStatistics>> = [
    {
      type: 'selection',
      prop: 'key',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'title',
      label: 'prepressStatistics.personnelName',
      minWidth: '230px',
      showOverflowTooltip: true
    },
    {
      prop: 'finishTotal',
      label: 'prepressStatistics.reviewsTotal',
      minWidth: '230px',
      showOverflowTooltip: true
    },
    {
      prop: 'confirmTotal',
      label: 'prepressStatistics.confirmedTotal',
      minWidth: '230px',
      showOverflowTooltip: true
    },
    {
      prop: 'confirmRatio',
      label: 'prepressStatistics.averageConfirmationRate',
      minWidth: '230px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return (row as PrepressStatistics).confirmRatio.toFixed(2);
      }
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption<any>> = [
    {
      type: 'DateRangePicker',
      field: 'createTime',
      label: 'prepressStatistics.dateRange',
      option: {
        rangeSeparator: '~',
        pickerOptions: {
          disabledDate(callbackDateStr: string): boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();
            return callbackDateTime >= currentDateTime;
          }
        },
        clearable: false
      }
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.export',
      operationType: 'export',
      icon: 'el-icon-download',
      plain: true,
      permissionCode: 'prepress:statistics:personalStatisticsExport',
      handleClick: this.export
    }
  ];

  public selectedRows: Array<PrepressStatistics> = [];

  public paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  // TODO 类型未定义
  private queryForm: Partial<any> = {
    startTime: `${new Date().getFullYear()}-01-01 00:00:00`,
    endTime: `${dateFormat(new Date(), 'YYYY-MM-DD') + ' 23:59:59'}`,
    createTime: [`${new Date().getFullYear()}-01-01 00:00:00 `, dateFormat(new Date(), 'YYYY-MM-DD') + ' 23:59:59']
  };

  private myChart: any;

  private echartsList = [
    { time: 1, count: 2 },
    { time: 2, count: 8 },
    { time: 3, count: 4 },
    { time: 4, count: 7 },
    { time: 5, count: 8 },
    { time: 6, count: 10 }
  ];
  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'departmentAverageConfirmationRate');
    this.loadData();
  }

  public activated(): void {
    this.loadData();
  }

  public mounted(): void {}

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.clearSelection();
    this.loadData();
  }

  public handleSelectionChange(selectedData: Array<PrepressStatistics>): void {
    this.selectedRows = selectedData;
  }

  private async export(): Promise<void> {
    this.tableOption.loading = true;
    try {
      const blob = await preperssStatisticsService.personalStatisticsExport(this.queryForm);
      downloadFileByBlob(
        `${translation('route.personalAverageConfirmationRate')}_【${dateFormat(
          this.queryForm.startTime,
          'YYYY-MM-DD'
        )}至${dateFormat(this.queryForm.endTime, 'YYYY-MM-DD')}】.xlsx`,
        blob
      );
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  @debounce()
  private loadData(): void {
    if (changeQueryTime(this.queryForm.startTime, this.queryForm.endTime)) {
      Message.error(translation('prepressStatistics.timeTip'));
      return;
    }
    this.tableOption.loading = true;
    preperssStatisticsService
      .getPersonalStatistics(this.queryForm)
      .then(res => {
        this.tableOption.data = res || [];
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }
  private clearSelection(): void {
    (this.$refs.departmentAverageConfirmationRateTable as OsTable).clearSelection();
    this.selectedRows = [];
  }

  @Watch('queryForm.createTime')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.startTime = undefined;
      this.queryForm.endTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.startTime = dateFormat(value[0]);
      this.queryForm.endTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }
}
