import { preperssStatisticsService } from '@/api';

import {
  LogisticsOrderStatusEnum,
  OrderTypeEnum,
  PrepressActionEnum,
  PrepressStatusEnum,
  ProductionActionEnum
} from '@/resource/enum';
import { ConfirmResultEnum } from '@/resource/enum/project-item-type';
import { BillingList } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

interface LogisticsTrackingList {
  code: string;
  deliveryShopName: string;
  status: LogisticsOrderStatusEnum;
}

@Component({
  name: 'WorkContentDetails',
  components: {}
})
export default class WorkContentDetails extends Vue {
  @Prop({ type: Object, required: true })
  public params!: { id: number; itemType: number; modifyCount: number };

  public projectProduct: any | null = null;

  public get prepressStatus(): string {
    const str = translation(`prepressStatus.${PrepressStatusEnum[this.projectProduct!.prepressStatus]}`);
    return this.projectProduct!.prepressStatus ? str : '';
  }

  public get productionArea(): string {
    return (
      (this.projectProduct!.finishWidth / 1000) *
      (this.projectProduct!.finishHeight / 1000) *
      this.projectProduct!.count
    ).toFixed(2);
  }

  public get visibleSize(): string {
    return `${this.projectProduct!.visibleWidth}mm × ${this.projectProduct!.visibleHeight}mm`;
  }

  public get finishSize(): string {
    return `${this.projectProduct!.finishWidth}mm × ${this.projectProduct!.finishHeight}mm`;
  }
  public get workload(): number {
    return this.projectProduct!.prepressSubmitCount * this.projectProduct!.factor;
  }
  public get screenRate(): string {
    return (this.projectProduct!.visibleWidth / this.projectProduct!.visibleHeight).toFixed(3);
  }

  public get itemType(): string {
    return translation(`projectItemType.${OrderTypeEnum[this.projectProduct!.itemType]}`);
  }
  public get confirmResult(): string {
    return translation(`confirmResultEnum.${ConfirmResultEnum[this.projectProduct!.confirmResult]}`);
  }

  public getPrepressActionName(action: PrepressActionEnum): string {
    return translation(`prepressAction.${PrepressActionEnum[action]}`);
  }

  public getProductionActionName(action: ProductionActionEnum): string {
    return translation(`productionAction.${ProductionActionEnum[action]}`);
  }

  public linkToLogistics(rowData: LogisticsTrackingList): void {
    this.$emit('linkToLogistics');
    this.$router.push({
      path: 'logistics',
      query: {
        logisticsNo: rowData.code
      }
    });
  }
  public linkToBillList(rowData: BillingList): void {
    this.$emit('linkToLogistics');
    this.$router.push({
      path: 'billing',
      query: {
        keywords: rowData.billCode
      }
    });
  }

  private getProjectProduct(): void {
    preperssStatisticsService
      .getById(this.params)
      .then(res => {
        this.projectProduct = res;
      })
      .catch(error => {
        messageError(error);
      });
  }

  @Watch('projectProductId', { immediate: true })
  private init(): void {
    this.getProjectProduct();
  }
}
