import { DialogMixin } from '@/mixins/dialog';
import { CurrencyResource } from '@/resource/model';
import { Form } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'CurrencyDetails'
})
export default class CurrencyDetails extends mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public position!: CurrencyResource | null;
  public disabled: boolean = true;

  public currencyForm: CurrencyResource = {
    id: undefined,
    code: '',
    name: '',
    currencyCode: '',
    pricePrecision: 6,
    amountPrecision: 2
  };

  private operationType: 'add' | 'edit' = 'add';

  public dialogOpen(): void {
    if (this.position) {
      this.operationType = 'edit';
      this.title = 'currency.title';
      this.$nextTick(() => {
        Object.assign(this.currencyForm, this.position);
      });
      return;
    }
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.currencyForm as Form).resetFields();
  }
}
