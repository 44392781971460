import { currencyService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { CurrencyResource } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'AddCurrency'
})
export default class AddCurrency extends mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public position!: CurrencyResource | null;

  public currencyForm: CurrencyResource = {
    id: undefined,
    code: '',
    name: '',
    currencyCode: '',
    pricePrecision: 6,
    amountPrecision: 2
  };

  public resourceFormRules = {
    code: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('currency.inputCode')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          currencyService
            .checkCode(value, this.currencyForm?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('common.codeRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    name: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('currency.inputName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          currencyService
            .checkName(value, this.position?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.regionNameRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    currencyCode: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('currency.inputCurrencyCode')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          currencyService
            .checkCurrencyCode(value, this.position?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('common.codeRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    pricePrecision: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          console.log(!value?.toString());
          if (!value?.toString()) {
            callback(new Error(translation('currency.inputPricePrecision')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    amountPrecision: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value?.toString()) {
            callback(new Error(translation('currency.inputAmountPrecision')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  };

  private operationType: 'add' | 'edit' = 'add';

  public dialogOpen(): void {
    if (this.position) {
      this.operationType = 'edit';
      this.title = 'currency.title';
      this.$nextTick(() => {
        Object.assign(this.currencyForm, this.position);
      });
      return;
    }
    this.operationType = 'add';
    this.title = 'currency.title';
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.currencyForm as Form).resetFields();
  }

  public onSubmit(): void {
    (this.$refs.currencyForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        if (this.operationType === 'add') {
          await currencyService.post({ ...this.currencyForm } as any);
          Message.success(translation('operationRes.operationSuccess'));
          this.$emit('operate-success');
          this.closeDialog();
          return;
        }
        await currencyService.put({ ...this.currencyForm } as any);
        Message.success(translation('operationRes.operationSuccess'));
        this.$emit('operate-success', this.currencyForm);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }
}
