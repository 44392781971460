import { render, staticRenderFns } from "./unit-measure-details.vue?vue&type=template&id=3f5f44e2&scoped=true&"
import script from "./unit-measure-details.ts?vue&type=script&lang=ts&"
export * from "./unit-measure-details.ts?vue&type=script&lang=ts&"
import style0 from "./unit-measure-details.scoped.scss?vue&type=style&index=0&id=3f5f44e2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f5f44e2",
  null
  
)

export default component.exports