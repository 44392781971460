import { DialogMixin } from '@/mixins/dialog';
import { ResourceStatusEnum, PublicApproveStatusEnum } from '@/resource/enum';
import { UnitOfMeasurementGroupList } from '@/resource/model';
import { translation } from '@/utils';
import { Form } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'units-measure-groups-details'
})
export default class unitsMeasureGroupsDetails extends mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public position!: UnitOfMeasurementGroupList | null;
  public disabled: boolean = true;

  public unitsMeasureGroupsForm: UnitOfMeasurementGroupList = {
    id: 0,
    code: '',
    name: '',
    datumCode: '',
    datumName: '',
    datumPrecision: 0,
    describe: '',
    createTime: '',
    createUserName: '',
    updateTime: '',
    updateUserName: '',
    forbiddenTime: '',
    forbiddenUserName: '',
    approveTime: '',
    approveUserName: '',
    enableStatus: 0,
    approveStatus: 0
  };

  private operationType: 'add' | 'edit' = 'add';

  public dialogOpen(): void {
    if (this.position) {
      this.operationType = 'edit';
      this.title = 'unitsMeasureGroups.title';
      this.$nextTick(() => {
        Object.assign(this.unitsMeasureGroupsForm, this.position);
      });
      return;
    }
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.unitsMeasureGroupsForm as Form).resetFields();
  }

  private get getApproveStatusI18Key(): string {
    return translation('publicApproveStatus.' + PublicApproveStatusEnum[this.unitsMeasureGroupsForm.approveStatus]);
  }
  private get getStatusI18Key(): string {
    let res;
    switch (this.unitsMeasureGroupsForm.enableStatus) {
      case ResourceStatusEnum.using:
        res = 'common.startUsing';
        break;
      case ResourceStatusEnum.disabled:
        res = 'common.disabled';
        break;
      default:
        res = 'common.unKnownStatus';
        break;
    }
    return translation(res);
  }
}
