import { Component, Watch } from 'vue-property-decorator';
import { OsTable } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { ExchangeRateList, ExchangeRateQuery, ExchangeRateResource, ImportRes } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import {
  messageError,
  translation,
  getResourceStatusOptions,
  getResourceApproveStatusOptions,
  dateFormat,
  debounce,
  downloadFileByBlob,
  handleImportError
} from '@/utils';
import { Message, MessageBox } from 'element-ui';
import { ResourceStatusEnum } from '@/resource/enum';
import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
import { exchangeRateService } from '@/api/';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { Paging, SortOptions } from '@/api/base';
import { MessageBoxData } from 'element-ui/types/message-box';
import AddExchangeRate from './add-exchange-rate/add-exchange-rate.vue';
import { PublicApproveStatusEnum } from '@/resource/enum/approve-status';
import { ImportFile } from '@/views/dialogs';
import { ApiResponse } from '@/api/axios';

@Component({
  name: 'ExchangeRate',
  components: { AddExchangeRate, ImportFile }
})
export default class ExchangeRate extends mixins(PagingMixin, CustomColumnMixin) {
  public tableOption: OsTableOption<ExchangeRateList> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 默认的表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<ExchangeRateList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'sourceCurrencyName',
      label: 'exchangeRate.sourceCurrencyName',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'targetCurrencyName',
      label: 'exchangeRate.targetCurrencyName',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'sourceExchangeRate',
      label: 'exchangeRate.sourceExchangeRate',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'targetExchangeRate',
      label: 'exchangeRate.targetExchangeRate',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'effectiveStartTime',
      label: 'exchangeRate.effectiveStartTime',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return dateFormat((row as ExchangeRateList).effectiveStartTime, 'YYYY-MM-DD');
      }
    },
    {
      prop: 'effectiveEndTime',
      label: 'exchangeRate.effectiveEndTime',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return dateFormat((row as ExchangeRateList).effectiveEndTime, 'YYYY-MM-DD');
      }
    },
    {
      prop: 'approveStatus',
      label: 'exchangeRate.approveStatus',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'enableStatus',
      label: 'exchangeRate.enableStatus',
      minWidth: '100px',
      showOverflowTooltip: true
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption<ExchangeRateQuery>> = [
    {
      type: 'Input',
      field: 'sourceCurrencyName',
      label: 'exchangeRate.sourceCurrencyName',
      option: {
        placeholder: 'exchangeRate.sourceCurrencyName'
      }
    },
    {
      type: 'Input',
      field: 'targetCurrencyName',
      label: 'exchangeRate.targetCurrencyName',
      option: {
        placeholder: 'exchangeRate.targetCurrencyName'
      }
    },
    {
      type: 'Select',
      field: 'approveStatus',
      label: 'unitsMeasureGroups.approveStatus',
      option: {
        placeholder: 'unitsMeasureGroups.approveStatus'
      },
      optionData: getResourceApproveStatusOptions
    },
    {
      type: 'Select',
      field: 'enableStatus',
      label: 'unitsMeasureGroups.enableStatus',
      option: {
        placeholder: 'unitsMeasureGroups.enableStatus'
      },
      optionData: getResourceStatusOptions
    },
    {
      type: 'DateRangePicker',
      field: 'effective',
      label: 'exchangeRate.effective',
      option: {
        rangeSeparator: '~'
      }
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'base:exchangeRate:save',
      handleClick: (): void => {
        this.add();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.delete',
      operationType: 'delete',
      icon: 'el-icon-delete',
      permissionCode: 'base:exchangeRate:delete',
      plain: true,
      disabled: true,
      handleClick: (): void => {
        this.batchDelete();
      }
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'unitsMeasureGroups.batchAudit',
      operationType: 'batchAudit',
      permissionCode: 'base:exchangeRate:approve',
      disabled: true,
      handleClick: (): void => {
        this.batchAudit();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'unitsMeasureGroups.batchReject',
      operationType: 'batchReject',
      permissionCode: 'base:exchangeRate:cancelApprove',
      plain: true,
      disabled: true,
      handleClick: (): void => {
        this.batchReject();
      }
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.import',
      operationType: 'import',
      permissionCode: 'base:exchangeRate:importData',
      handleClick: (): void => {
        this.openImportFileDialog();
      }
    },
    {
      type: 'primary',
      slot: 'end',
      label: 'button.using',
      operationType: 'using',
      icon: 'el-icon-open',
      permissionCode: 'base:exchangeRate:editEnableStatus',
      handleClick: (): void => {
        this.batchUpdateStatus(ResourceStatusEnum.using);
      },
      disabled: true
    },
    {
      type: 'danger',
      slot: 'end',
      plain: true,
      label: 'button.disabled',
      operationType: 'disabled',
      icon: 'el-icon-turn-off',
      permissionCode: 'base:exchangeRate:editEnableStatus',
      handleClick: (): void => {
        this.batchUpdateStatus(ResourceStatusEnum.disabled);
      },
      disabled: true
    }
  ];

  public dialogVisible: boolean = false;
  public importDialogVisible: boolean = false;
  public importTitle = 'dialog.importExchangeRate';

  public editRow: ExchangeRateResource | null = null;

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<ExchangeRateList> = {
    fixed: 'right',
    width: '100px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'base:exchangeRate:edit',
        dynamicHidden: (rowData: ExchangeRateList): boolean => {
          return (
            rowData.enableStatus === ResourceStatusEnum.disabled ||
            rowData.approveStatus === PublicApproveStatusEnum.passed
          );
        },
        handleClick: this.edit
      }
    ]
  };

  public selectedRows: Array<ExchangeRateList> = [];
  public paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  private queryForm: Partial<ExchangeRateQuery> = {
    effective: []
  };

  private sortOptions: SortOptions<ExchangeRateList> = this.tableOption.defaultSort!;

  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'exchangeRate');
    this.loadData();
  }

  public activated(): void {
    this.loadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.clearSelection();
    this.loadData();
  }

  public handleSortChange(sortOptions: SortOptions<ExchangeRateList>): void {
    this.sortOptions = sortOptions;
    this.reloadData();
  }

  public linkToDetails(deliveryNotice: ExchangeRateList): void {
    // 打开详情 弹窗
    console.log('打开详情', deliveryNotice);
  }

  public handleSelectionChange(selectedData: Array<ExchangeRateList>): void {
    this.selectedRows = selectedData;
  }

  public getStatusI18Key(status: ResourceStatusEnum): string {
    let res;
    switch (status) {
      case ResourceStatusEnum.using:
        res = 'common.startUsing';
        break;
      case ResourceStatusEnum.disabled:
        res = 'common.disabled';
        break;
      default:
        res = 'common.unKnownStatus';
        break;
    }
    return res;
  }

  public getStatusClass(status: ResourceStatusEnum): string {
    return status === ResourceStatusEnum.using ? 'start-dot' : 'disabled-dot';
  }
  public getApproveStatusClass(status: PublicApproveStatusEnum): string {
    return status === PublicApproveStatusEnum.passed ? 'success-dot' : 'disabled-dot';
  }

  public getApproveStatusI18Key(status: PublicApproveStatusEnum): string {
    return 'publicApproveStatus.' + PublicApproveStatusEnum[status];
  }
  @debounce()
  private loadData(): void {
    this.tableOption.loading = true;
    exchangeRateService
      .getList(this.queryForm, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total || 0;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<ExchangeRateList>): void {
    this.operationOptions.forEach(x => {
      if (!['import', 'add'].includes(x.operationType)) {
        x.disabled = value.length === 0;
      }
    });
  }

  @Watch('queryForm.effective')
  private handleRequiredEffectiveTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.effectiveStartTime = undefined;
      this.queryForm.effectiveEndTime = undefined;
      return;
    }
    if (value.length === 2) {
      this.queryForm.effectiveStartTime = dateFormat(value[0], 'YYYY-MM-DD');
      this.queryForm.effectiveEndTime = dateFormat(value[1], 'YYYY-MM-DD');
    }
  }

  private clearSelection(): void {
    (this.$refs.exchangeRateTable as OsTable).clearSelection();
    this.selectedRows = [];
  }

  private add(): void {
    console.log('打开弹窗');
    // 新增数据
    this.dialogVisible = true;
  }
  private edit(rowData: ExchangeRateList): void {
    // 修改数据
    this.editRow = rowData;
    this.dialogVisible = true;
  }

  private dialogClosed(): void {
    this.editRow = null;
  }
  private deleteConfirm(): Promise<MessageBoxData> {
    return MessageBox.confirm(translation('tip.confirmDelete'), translation('tip.tipInfo'), {
      confirmButtonText: translation('button.ok'),
      cancelButtonText: translation('button.cancel'),
      type: 'warning'
    });
  }
  /**
   * 批量删除
   */
  private async batchDelete(): Promise<void> {
    const idList: Array<number> = this.selectedRows
      .filter(
        item =>
          item.approveStatus === PublicApproveStatusEnum.waitApprove &&
          Number(item.enableStatus) === ResourceStatusEnum.using
      )
      .map(x => x.id!);

    if (idList.length === 0) {
      Message.warning(translation('exchangeRate.selectDeleteTip1'));
      return;
    }
    this.deleteConfirm()
      .then(async () => {
        try {
          await exchangeRateService.batchCurrencyDelete(idList);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }
  /**
   * 审核
   */
  private async batchAudit(): Promise<void> {
    try {
      const idList: Array<number> = this.selectedRows
        .filter(
          item =>
            item.approveStatus === PublicApproveStatusEnum.waitApprove &&
            Number(item.enableStatus) === ResourceStatusEnum.using
        )
        .map(x => x.id!);
      if (idList.length === 0) {
        Message.error(translation('exchangeRate.selectWaitApproveData'));
        return;
      }
      await exchangeRateService.batchCurrencyApprove(idList);
      this.reloadData();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      messageError(error);
    }
  }

  /**
   * 反审核
   */
  private async batchReject(): Promise<void> {
    try {
      const idList: Array<number> = this.selectedRows
        .filter(
          item =>
            item.approveStatus === PublicApproveStatusEnum.passed &&
            Number(item.enableStatus) === ResourceStatusEnum.using
        )
        .map(x => x.id!);
      if (idList.length === 0) {
        Message.error(translation('exchangeRate.selectPassedData'));
        return;
      }
      await exchangeRateService.batchCurrencyCancelApprove(idList);
      this.reloadData();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      messageError(error);
    }
  }

  private openImportFileDialog(): void {
    this.importDialogVisible = true;
  }
  private downloadTemplate(): void {
    (this.$refs.importDialog as ImportFile).setDownloadLoading(true);
    exchangeRateService
      .downloadTemplate()
      .then((blob: any) => {
        downloadFileByBlob('汇率导入模板.xlsx', blob);
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        (this.$refs.importDialog as ImportFile).setDownloadLoading(false);
      });
  }
  private importSuccess(path: string): void {
    exchangeRateService
      .importCustomer(path)
      .then(() => {
        this.reloadData();
        Message.success(translation('dialog.importSuccess'));
        this.importDialogVisible = false;
      })
      .catch((error: ApiResponse<ImportRes>) => {
        handleImportError(error);
        (this.$refs.importDialog as ImportFile).setLoading(false);
      });
  }

  /**
   * 批量修改计量单位分组状态
   * @param status 状态 启用还是禁用
   */
  private batchUpdateStatus(status: ResourceStatusEnum): void {
    const idList = this.selectedRows.filter(item => item.enableStatus !== status).map(x => x.id!);
    if (idList.length === 0) {
      const error =
        status === ResourceStatusEnum.using
          ? translation('common.selectDisabledData')
          : translation('common.selectUsingData');
      Message.error(error);
      return;
    }
    exchangeRateService
      .batchCurrentUpdateStatus(idList, status)
      .then(() => {
        this.reloadData();
        Message.success(
          status === ResourceStatusEnum.using
            ? translation('common.usingSuccess')
            : translation('common.disabledSuccess')
        );
      })
      .catch(error => {
        messageError(error);
      });
  }
}
