import { Component, Vue, Watch } from 'vue-property-decorator';
import { OsTable } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { ImportRes, UnitOfMeasurementList, UnitOfMeasurementListQuery } from '@/resource/model';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { Paging } from '@/api/base';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { unitsMeasureGroupsService, unitsMeasureService } from '@/api';
import {
  debounce,
  downloadFileByBlob,
  getResourceApproveStatusOptions,
  getResourceStatusOptions,
  handleImportError,
  messageError,
  translation
} from '@/utils';
import { Message, MessageBox } from 'element-ui';
import { RegionTreeResource } from '@/resource/model/public-data/region';
import { ResourceStatusEnum, PublicApproveStatusEnum, SystemPresetEnum } from '@/resource/enum';
import { MessageBoxData } from 'element-ui/types/message-box';
import AddUnitMeasure from './add-unit-measure/add-unit-measure.vue';
import { ImportFile } from '@/views/dialogs';
import { ApiResponse } from '@/api/axios';
import UnitMeasureDetails from './unit-measure-details/unit-measure-details.vue';

@Component({
  components: {
    AddUnitMeasure,
    UnitMeasureDetails,
    ImportFile
  }
})
export default class UnitMeasure extends Vue {
  public tableOption: OsTableOption<UnitOfMeasurementList> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<UnitOfMeasurementList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'code',
      label: 'unitsMeasure.code',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'name',
      label: 'unitsMeasure.name',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'datumFlag',
      label: 'unitsMeasure.datumFlag',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return translation(`${this.getDatumFlagI18Key((row as UnitOfMeasurementList).datumFlag)}`);
      }
    },
    {
      prop: 'approveStatus',
      label: 'unitsMeasure.approveStatus',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'enableStatus',
      label: 'unitsMeasure.enableStatus',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'systemFlag',
      label: 'unitsMeasure.systemFlag',
      minWidth: '100px',
      showOverflowTooltip: true
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption<UnitOfMeasurementListQuery>> = [
    {
      type: 'Input',
      field: 'code',
      label: 'unitsMeasureGroups.code',
      option: {
        placeholder: 'unitsMeasureGroups.code'
      }
    },
    {
      type: 'Input',
      field: 'name',
      label: 'unitsMeasureGroups.name',
      option: {
        placeholder: 'unitsMeasureGroups.name'
      }
    },
    {
      type: 'Select',
      field: 'approveStatus',
      label: 'unitsMeasureGroups.approveStatus',
      option: {
        placeholder: 'unitsMeasureGroups.approveStatus'
      },
      optionData: getResourceApproveStatusOptions
    },
    {
      type: 'Select',
      field: 'enableStatus',
      label: 'unitsMeasureGroups.enableStatus',
      option: {
        placeholder: 'unitsMeasureGroups.enableStatus'
      },
      optionData: getResourceStatusOptions
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      disabled: false,
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'base:unitOfMeasurement:save',
      handleClick: (): void => {
        this.unitOfMeasurementAdd();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.delete',
      operationType: 'delete',
      icon: 'el-icon-delete',
      permissionCode: 'base:unitOfMeasurement:delete',
      plain: true,
      disabled: true,
      handleClick: (): void => {
        this.batchDelete();
      }
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'unitsMeasureGroups.batchAudit',
      operationType: 'batchAudit',
      permissionCode: 'base:unitOfMeasurement:approve',
      disabled: true,
      handleClick: (): void => {
        this.batchAudit();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'unitsMeasureGroups.batchReject',
      operationType: 'batchReject',
      permissionCode: 'base:unitOfMeasurement:cancelApprove',
      plain: true,
      disabled: true,
      handleClick: (): void => {
        this.batchReject();
      }
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.import',
      operationType: 'import',
      permissionCode: 'base:unitOfMeasurement:importData',
      handleClick: (): void => {
        this.openImportFileDialog();
      }
    },
    {
      type: 'primary',
      slot: 'end',
      label: 'button.using',
      operationType: 'using',
      icon: 'el-icon-open',
      permissionCode: 'base:unitOfMeasurement:editEnableStatus',
      handleClick: (): void => {
        this.batchUpdateStatus(ResourceStatusEnum.using);
      },
      disabled: true
    },
    {
      type: 'danger',
      slot: 'end',
      plain: true,
      label: 'button.disabled',
      operationType: 'disabled',
      icon: 'el-icon-turn-off',
      permissionCode: 'base:unitOfMeasurement:editEnableStatus',
      handleClick: (): void => {
        this.batchUpdateStatus(ResourceStatusEnum.disabled);
      },
      disabled: true
    }
  ];

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<UnitOfMeasurementList> = {
    fixed: 'right',
    width: '100px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'base:unitOfMeasurement:edit',
        dynamicHidden: (rowData: UnitOfMeasurementList): boolean => {
          return (
            Number(rowData.enableStatus) === ResourceStatusEnum.disabled ||
            Number(rowData.approveStatus) !== PublicApproveStatusEnum.waitApprove
          );
        },
        handleClick: (item: UnitOfMeasurementList): void => {
          this.unitOfMeasurementEdit(item);
        }
      }
    ]
  };
  public totalData = 0;
  public selectedRows: Array<UnitOfMeasurementList> = [];

  public treeList: Array<RegionTreeResource> = [
    {
      id: 0,
      level: 0,
      name: '全部',
      valueList: [0],
      child: []
    }
  ];

  public parentData: RegionTreeResource = this.treeList[0];

  public dialogVisible: boolean = false;
  public dialogVisibleDetails: boolean = false;
  public importDialogVisible: boolean = false;
  public importTitle = 'dialog.importUnitMeasure';

  public editRow: UnitOfMeasurementList | null = null;

  public defaultProps: { children: string; label: string } = {
    children: 'child',
    label: 'name'
  };

  private queryForm: UnitOfMeasurementListQuery = {
    groupId: null,
    code: '',
    name: '',
    enableStatus: null,
    approveStatus: null
  };

  private paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public getStatusI18Key(status: ResourceStatusEnum): string {
    let res;
    switch (Number(status)) {
      case ResourceStatusEnum.using:
        res = 'common.startUsing';
        break;
      case ResourceStatusEnum.disabled:
        res = 'common.disabled';
        break;
      default:
        res = 'common.unKnownStatus';
        break;
    }
    return res;
  }

  public getStatusClass(status: ResourceStatusEnum): string {
    return Number(status) === ResourceStatusEnum.using ? 'start-dot' : 'disabled-dot';
  }

  public getSystemFlagI18Key(systemFlag: number): string {
    return systemFlag === 1 ? 'common.yes' : 'common.no';
  }
  public getDatumFlagI18Key(datumFlag: number): string {
    return Number(datumFlag) === 1 ? 'common.yes' : 'common.no';
  }
  public getApproveStatusClass(status: PublicApproveStatusEnum): string {
    return Number(status) === PublicApproveStatusEnum.passed ? 'success-dot' : 'disabled-dot';
  }

  public getApproveStatusI18Key(status: PublicApproveStatusEnum): string {
    return 'publicApproveStatus.' + PublicApproveStatusEnum[status];
  }
  public async activated(): Promise<void> {
    await this.getTreeList();
    this.loadData();
  }
  public async created(): Promise<void> {
    await this.getTreeList();
    this.loadData();
  }

  public queryClick(): void {
    this.reloadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    (this.$refs.regionTable as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }

  public handleSelectionChange(selectedData: Array<UnitOfMeasurementList>): void {
    this.selectedRows = selectedData;
  }

  public handleNodeClick(nodeData: any): void {
    this.parentData = nodeData;
    this.reloadData();
  }

  public unitOfMeasurementAdd(): void {
    this.editRow = null;
    this.dialogVisible = true;
  }
  public async unitOfMeasurementEdit(item: UnitOfMeasurementList): Promise<void> {
    this.editRow = await unitsMeasureService.getById(item.id!);
    this.dialogVisible = true;
  }
  public async linkToItemDetails(rowData: UnitOfMeasurementList): Promise<void> {
    this.editRow = await unitsMeasureService.getById(rowData.id!);
    this.dialogVisibleDetails = true;
  }

  public dialogClosed(): void {
    this.editRow = null;
    this.reloadData();
  }
  public editSuccess(): void {
    this.reloadData();
    this.editRow = null;
  }
  /**
   * 删除确认弹窗
   * @returns
   */
  private deleteConfirm(): Promise<MessageBoxData> {
    return MessageBox.confirm(translation('tip.confirmDelete'), translation('tip.tipInfo'), {
      confirmButtonText: translation('button.ok'),
      cancelButtonText: translation('button.cancel'),
      type: 'warning'
    });
  }
  /**
   * 批量删除
   */
  private async batchDelete(): Promise<void> {
    const idList: Array<number> = this.selectedRows
      .filter(
        item =>
          Number(item.approveStatus) === PublicApproveStatusEnum.waitApprove &&
          Number(item.enableStatus) === ResourceStatusEnum.using &&
          Number(item.systemFlag) === SystemPresetEnum.No &&
          Number(item.datumFlag) !== 1
      )
      .map(x => x.id!);

    if (idList.length === 0) {
      Message.warning(translation('exchangeRate.selectDeleteTip'));
      return;
    }
    this.deleteConfirm()
      .then(async () => {
        try {
          await unitsMeasureService.batchDelete(idList);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }
  /**
   * 审核
   */
  private async batchAudit(): Promise<void> {
    try {
      const idList: Array<number> = this.selectedRows
        .filter(
          item =>
            Number(item.approveStatus) === PublicApproveStatusEnum.waitApprove &&
            Number(item.enableStatus) === ResourceStatusEnum.using &&
            Number(item.systemFlag) === SystemPresetEnum.No &&
            Number(item.datumFlag) !== 1
        )
        .map(x => x.id!);
      if (idList.length === 0) {
        Message.error(translation('exchangeRate.selectWaitApproveData'));
        return;
      }
      await unitsMeasureService.batchApprove(idList);
      this.reloadData();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      messageError(error);
    }
  }

  /**
   * 反审核
   */
  private async batchReject(): Promise<void> {
    try {
      const idList: Array<number> = this.selectedRows
        .filter(
          item =>
            Number(item.approveStatus) === PublicApproveStatusEnum.passed &&
            Number(item.enableStatus) === ResourceStatusEnum.using &&
            Number(item.systemFlag) === SystemPresetEnum.No &&
            Number(item.datumFlag) !== 1
        )
        .map(x => x.id!);
      if (idList.length === 0) {
        Message.error(translation('exchangeRate.selectPassedData'));
        return;
      }
      await unitsMeasureService.batchCancelApprove(idList);
      this.reloadData();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      messageError(error);
    }
  }

  /**
   * 打开Excel上传弹窗
   */
  private openImportFileDialog(): void {
    this.importDialogVisible = true;
  }
  /**
   * 下载上传模板
   */
  private downloadTemplate(): void {
    (this.$refs.importDialog as ImportFile).setDownloadLoading(true);
    unitsMeasureService
      .downloadTemplate()
      .then((blob: any) => {
        downloadFileByBlob('计量单位导入模板.xlsx', blob);
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        (this.$refs.importDialog as ImportFile).setDownloadLoading(false);
      });
  }
  private importSuccess(path: string): void {
    unitsMeasureService
      .importCustomer(path)
      .then(() => {
        this.reloadData();
        Message.success(translation('dialog.importSuccess'));
        this.importDialogVisible = false;
      })
      .catch((error: ApiResponse<ImportRes>) => {
        handleImportError(error);
        (this.$refs.importDialog as ImportFile).setLoading(false);
      });
  }

  /**
   * 批量修改计量单位分组状态
   * @param status 状态 启用还是禁用
   */
  private batchUpdateStatus(status: ResourceStatusEnum): void {
    const idList = this.selectedRows
      .filter(item => item.enableStatus !== status && Number(item.systemFlag) === SystemPresetEnum.No)
      .map(x => x.id!);
    if (idList.length === 0) {
      const error =
        status === ResourceStatusEnum.using
          ? translation('common.selectDisabledData')
          : translation('common.selectUsingData');
      Message.error(error);
      return;
    }
    unitsMeasureService
      .batchUpdateStatus(idList, status)
      .then(() => {
        this.reloadData();
        Message.success(
          status === ResourceStatusEnum.using
            ? translation('common.usingSuccess')
            : translation('common.disabledSuccess')
        );
      })
      .catch(error => {
        messageError(error);
      });
  }
  @debounce()
  private loadData(): void {
    this.tableOption.loading = true;
    if (!this.parentData!.id!) {
      this.queryForm.groupId = null;
    } else {
      this.queryForm.groupId = this.parentData!.id ? this.parentData!.id : null;
    }
    unitsMeasureService
      .getList(this.queryForm as UnitOfMeasurementListQuery, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private getTreeList(): void {
    unitsMeasureGroupsService
      .getUnitsOfMeasureList()
      .then(res => {
        res.forEach(item => {
          item.name = `${item.code}(${item.name})`;
          item.level = 1;
        });
        this.treeList[0].child = res as any;
        this.parentData = this.treeList[0];
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<any>): void {
    this.operationOptions.forEach(x => {
      if (!['add', 'import'].includes(x.operationType)) {
        x.disabled = value.length === 0;
      }
    });
  }
  // @Watch('parentData.level', { immediate: true, deep: true })
  // private handleClickChanged(value: number): void {
  //   this.operationOptions.forEach(x => {
  //     if (['add'].includes(x.operationType)) {
  //       x.disabled = value === 0;
  //     }
  //   });
  // }
}
