import { Component, Watch } from 'vue-property-decorator';
import { OsTable } from '@/components';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { PrepressStatistics } from '@/resource/model';

import { changeQueryTime, dateFormat, debounce, messageError, translation } from '@/utils';

import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
import { preperssStatisticsService } from '@/api/';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { Paging } from '@/api/base';
import { ImportFile } from '@/views/dialogs';
import { Message } from 'element-ui';

@Component({
  name: 'CustomerAverageConfirmationRound',
  components: { ImportFile }
})
export default class CustomerAverageConfirmationRound extends mixins(PagingMixin, CustomColumnMixin) {
  public tableOption: OsTableOption<PrepressStatistics> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 默认的表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<PrepressStatistics>> = [
    {
      type: 'selection',
      prop: 'key',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'customerName',
      label: 'installationNotice.customer',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'confirmTotal',
      label: 'prepressStatistics.confirmItemTotal',
      minWidth: '100px',

      showOverflowTooltip: true
    },
    {
      prop: 'reeditTotal',
      label: 'prepressStatistics.reeditTotal',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'avgConfirmReedit',
      label: 'prepressStatistics.avgConfirmReedit',
      minWidth: '100px',
      showOverflowTooltip: true
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption<any>> = [
    {
      type: 'DateRangePicker',
      field: 'createTime',
      label: 'prepressStatistics.dateRange',
      option: {
        rangeSeparator: '~',
        pickerOptions: {
          disabledDate(callbackDateStr: string): boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();
            return callbackDateTime >= currentDateTime;
          }
        },
        clearable: false
      }
    }
  ];

  public selectedRows: Array<PrepressStatistics> = [];
  public paging: Paging = {
    currentPage: 1,
    showCount: 10
  };
  // TODO 类型未定义
  private queryForm: Partial<any> = {
    startTime: `${new Date().getFullYear()}-01-01 00:00:00`,
    endTime: `${dateFormat(new Date())}`,
    createTime: [new Date(`${new Date().getFullYear()}-01-01 00:00:00 `), new Date()]
  };

  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'customerAverageConfirmationRound');
    this.loadData();
  }

  public activated(): void {
    this.loadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.clearSelection();
    this.loadData();
  }

  public handleSelectionChange(selectedData: Array<PrepressStatistics>): void {
    this.selectedRows = selectedData;
  }

  @debounce()
  private loadData(): void {
    if (changeQueryTime(this.queryForm.startTime, this.queryForm.endTime)) {
      Message.error(translation('prepressStatistics.timeTip'));
      return;
    }
    this.tableOption.loading = true;
    preperssStatisticsService
      .getCustomerStatistics(this.queryForm, this.paging)
      .then(res => {
        this.tableOption.data = res.data || [];
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private clearSelection(): void {
    (this.$refs.customerAverageConfirmationRoundTable as OsTable).clearSelection();
    this.selectedRows = [];
  }

  @Watch('queryForm.createTime')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.startTime = undefined;
      this.queryForm.endTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.startTime = dateFormat(value[0]);
      this.queryForm.endTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }
}
