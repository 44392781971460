import { exchangeRateService } from '@/api';

import { DialogMixin } from '@/mixins/dialog';
import { CurrencyList, ExchangeRateResource } from '@/resource/model';

import { decimalToNumber6, limitFutureForTimePicker, messageError, translation } from '@/utils';
import Decimal from 'decimal.js';
import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import { SelectCurrency } from '@/views/dialogs';

@Component({
  name: 'AddExchangeRate',
  components: { SelectCurrency }
})
export default class AddExchangeRate extends mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public position!: ExchangeRateResource | null;
  public currencyDialogVisible: boolean = false;
  public valueType: string = '';

  public exchangeRateForm: ExchangeRateResource = {
    id: undefined,
    targetCurrencyId: null,
    sourceCurrencyId: null,
    targetCurrencyName: '',
    sourceCurrencyName: '',
    sourceExchangeRate: 1.0,
    targetExchangeRate: 1.0,
    effectiveStartTime: '',
    effectiveEndTime: ''
  };

  public resourceFormRules = {
    targetCurrencyName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('exchangeRate.selectTargetCurrency')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    sourceCurrencyName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('exchangeRate.selectSourceCurrency')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    sourceExchangeRate: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('exchangeRate.inputSourceExchangeRate')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    targetExchangeRate: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('exchangeRate.inputTargetExchangeRate')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    effectiveStartTime: [
      {
        required: true,
        validator: (rule: any, value: Date, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('exchangeRate.selectEffectiveStartTime')));
            return;
          }
          const { effectiveEndTime } = this.exchangeRateForm;

          if (effectiveEndTime && new Date(value) > new Date(effectiveEndTime)) {
            callback(new Error(translation('exchangeRate.effectivetimeErrorTip')));
            return;
          }
          // (this.$refs.exchangeRateForm as ElForm).clearValidate();
          callback();
        },
        trigger: 'change'
      }
    ],
    effectiveEndTime: [
      {
        required: true,
        validator: (rule: any, value: Date, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('exchangeRate.selectEffectiveEndTime')));
            return;
          }
          const { effectiveStartTime } = this.exchangeRateForm;
          if (effectiveStartTime && new Date(value) < new Date(effectiveStartTime)) {
            callback(new Error(translation('exchangeRate.effectivetimeErrorTip')));
            return;
          }
          // (this.$refs.exchangeRateForm as ElForm).clearValidate();
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  public limitDateRangeOption = {
    disabledDate: limitFutureForTimePicker
  };

  private operationType: 'add' | 'edit' = 'add';

  public dialogOpen(): void {
    if (this.position) {
      this.operationType = 'edit';
      this.title = 'exchangeRate.title';
      this.$nextTick(() => {
        Object.assign(this.exchangeRateForm, this.position);
      });

      return;
    }
    this.operationType = 'add';
    this.title = 'exchangeRate.title';
    this.exchangeRateForm.effectiveStartTime = new Date();
    this.exchangeRateForm.effectiveEndTime = new Date('2099-12-31');
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.exchangeRateForm as Form).resetFields();
  }

  public openSelectCurrencyDialog(value: string): void {
    this.currencyDialogVisible = true;
    this.valueType = value;
  }
  public handleSelectedCurrency(currency: CurrencyList): void {
    switch (this.valueType) {
      case 'sourceCurrencyName':
        this.exchangeRateForm.sourceCurrencyName = currency.name;
        this.exchangeRateForm.sourceCurrencyId = currency.id;
        break;
      case 'targetCurrencyName':
        this.exchangeRateForm.targetCurrencyName = currency.name;
        this.exchangeRateForm.targetCurrencyId = currency.id;
        break;
    }
  }

  public onSubmit(): void {
    (this.$refs.exchangeRateForm as Form).validate(async (valid: boolean) => {
      console.log(valid);
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        if (this.operationType === 'add') {
          await exchangeRateService.post({ ...this.exchangeRateForm } as any);
          Message.success(translation('operationRes.operationSuccess'));
          this.$emit('operate-success');
          this.closeDialog();
          return;
        }
        await exchangeRateService.put({ ...this.exchangeRateForm } as any);
        Message.success(translation('operationRes.operationSuccess'));
        this.$emit('operate-success', this.exchangeRateForm);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }

  public changeSourceExchangeRate(): void {
    if (!this.exchangeRateForm.sourceExchangeRate) {
      return;
    }
    this.exchangeRateForm.targetExchangeRate = decimalToNumber6(
      Decimal.div(new Decimal(1), this.exchangeRateForm.sourceExchangeRate)
    );
  }
  public changeTargetExchangeRate(): void {
    if (!this.exchangeRateForm.targetExchangeRate) {
      return;
    }
    this.exchangeRateForm.sourceExchangeRate = decimalToNumber6(
      Decimal.div(new Decimal(1), this.exchangeRateForm.targetExchangeRate)
    );
  }
}
